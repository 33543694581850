// react 
import { useCallback, useEffect, useRef, useState, useContext } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
// ..........

// scss file
import './manageGst.scss'
// ..........

// package components
import { Button, Form, Modal, Table, Tag, Tooltip, Upload, message } from 'antd';
import YouTube from 'react-youtube';
// ..........

// child components
import { NotifyAlert } from '../../components/notify_alert'
import { serviceConfig } from '../../core/services'
import { useGlobalState } from '../../Statecontext'
import { ZTable2 } from '../../components/table2'
import { downloadServices } from '../../core/services/download'
import { Utils } from '../../core/utils/utils'
import { AppContext } from '../../core/utils/Context';
// ..........

// icons
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
import deleteConfirmIcon from '../../assets/images/deleteConfirm.svg'
import deleteIcon from '../../assets/images/deleteIcon.svg'
import editIcon from '../../assets/images/editIcon.svg'
import passwordDotIcon from '../../assets/images/passwordDot.svg'
import wrongPswdIcon from '../../assets/images/wrong_pswd.png'
import filterIcon from '../../assets/images/filter.svg'
import active_filtericon from '../../assets/images/filter_active_icon.svg';
import passwordIcon from '../../assets/images/password.svg'
import usernameIcon from '../../assets/images/username.svg'
// ..........

interface Data {
	auth_token_data: string;
	otp_token_data: string;
	username: string;
	password: string;
	captcha: string;
	otp?: string;
}

export const ManageGst = () => {
	let location = useLocation();

	// form values states
	const [otpres, setOtpres] = useState<Data | null>(null);
	const [actionType, setActionType] = useState("")
	const [editId, setEditId] = useState('')
	const [password_type, setPasswordType] = useState('password')
	const [uploadedfile, setUploadedFile] = useState<any>();
	const [detailSource, setDetailsource] = useState<any>([])
	const [form] = Form.useForm();
	const { formValues, handleChange, handleInitial } = useContext(AppContext)
	const { user_name, password, otp } = formValues;
	const [ismanage, setIsmanage] = useState(false)
	// ..........

	// alerts and modal states
	const [notifyOpen, setNotifyOpen] = useState(false)
	const [notifyType, setNotifyType] = useState<any>()
	const [notifyMessage, setNotifyMessage] = useState('')
	const [addGstLoadModal, setAddGstLoadModal] = useState(false)
	const [modal2Open, setModal2Open] = useState(false);
	const [modal3Open, setModal3Open] = useState(false);
	const [otpmodal, setOtpmodal] = useState(false);
	const [deleteact, setDeleteact] = useState(false)
	const [multideleteact, setMultideleteact] = useState(false);
	// ..........

	// loading state
	const [loading, setLoading] = useState(false);
	const [formloading, setFormloading] = useState(false);
	const [uploadcomplete, setUploadcomplete] = useState(true);
	const [uploading, setUploading] = useState(false)
	// ..........

	// limit & filter states
	// ..........

	//  datas states
	const [sel_gst_no, setSelGST] = useState<any>('')
	const [selectedgst, setSelectedgst] = useState<any>()
	const [gstList, setGstList] = useState<any>([])
	const [tabactive, setTabactive] = useState('form')
	const [isSyncAllProgress, setIsSyncAllProgress] = useState(false)
	// ..........

	// reference states
	const key = 'updatable';
	const playerRef = useRef<any>(null);
	const [messageApi] = message.useMessage();
	const manageGstDataLoading = useRef(false)
	const manageGstTable = useRef<any>()
	const { getSyncCount, handlesync } = Utils();
	const navigate = useNavigate();
	const ismanageRef = useRef(ismanage);
	const {action, setAction, manageactive, ismanagegst, setProgres, masterData, setMasterData, filterQuery, sorterQuery, searchquery, gstSyncModal, totalpages, setTotalpages, page, videopopup, setVideopopup, setGstSyncModal, setreportModal, rowsPerPage, setSyncDetails } = useGlobalState();
	// ..........

	const loadGst = useCallback((search_data?: any) => {
		manageGstDataLoading.current = true;
		setLoading(true)


		let query: any = {
			by_page: true,
			page: page,
			page_size: rowsPerPage
		}
		if (searchquery) {
			query['search'] = searchquery;
		}
		if (filterQuery.gst_no) {
			query['gst_no'] = filterQuery.gst_no.join(',')
		}
		if (filterQuery.state) {
			query['state'] = filterQuery.state.join(',')
		}
		if (filterQuery.trade_name) {
			query['trade'] = filterQuery.trade_name.join(',')
		}
		if (filterQuery.tradename) {
			query['trade_name'] = filterQuery.tradename.join(',')
		}
		if (filterQuery.user_name) {
			query['user_name'] = filterQuery.user_name.join(',')
		}
		if (filterQuery.wrong_password) {
			query['wrong_password'] = true
		}
		if (sorterQuery && sorterQuery.field) {
			query['sort'] = `${sorterQuery.field},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
		}

		if (manageactive) {
			if (manageactive === 'Active') {
				query['wrong_password'] = false
			} else {
				query['wrong_password'] = true
			}
		}
		serviceConfig.get("manageGst", true, query, null).then((data: any) => {

			setGstList(data.results)
			console.log(location)
			setTotalpages(data.count)
			setLoading(false)
			getSyncCount()
			manageGstDataLoading.current = false;
			// let findSyncing = data.results.map((sy: any) => { return sy.sync_status === 'Syncing' ? sy.id : null }).filter((n: any) => n)
			// findSyncing.length > 0 && setSyncIds(syncIds.filter(x => findSyncing.includes(x)))
			// let timerInt = isSyncAllProgress ? setTimeout(() => {
			// 	setSyncProcess(true);

			// 	loadGst();
			// }, (60*1000)) : null;
			// if (!isSyncAllProgress) {
			// 	timerInt = null;
			// }
		}, (err: any) => {
			console.log(err)
			setLoading(false)
			if (err.message === "Request failed with status code 401") {
				navigate('/login')
				localStorage.clear()
				window.location.reload();
			}
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [page, rowsPerPage, filterQuery, sorterQuery, action, manageactive])
	// .........


	// fetch filter data
	const fetchData = async () => {
		try {
			const gstData = await serviceConfig.get("master_gst", true, null, null);
			const statesData = await serviceConfig.get("master_states", true, null, null);
			const tradesData = await serviceConfig.get("master_trades", true, null, null);
			const usernamesData = await serviceConfig.get("master_usernames", true, null, null);

			setMasterData({
				...masterData,
				gst: gstData.data,
				states: statesData.data,
				trades: tradesData.data,
				userNames: usernamesData.data
			});

			localStorage.setItem('masterData', JSON.stringify({
				gst: gstData.data,
				states: statesData.data,
				trades: tradesData.data,
				userNames: usernamesData.data
			}));
		} catch (err) {
			console.log(err);
		}
	};
	// .........


	// edit or add gst function

	// Check if all values are not empty and if there are some errors
	const isError = useCallback(
		() =>
			Object.keys({ user_name, password }).some(
				(name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
			),
		[formValues, user_name, password]
	)


	const actionGst = () => {
		setFormloading(true)
		setModal2Open(false)
		setAddGstLoadModal(true)

		{ actionType !== "Edit" && setProgres({ data: { loading: true, label: 'Upload' } }) }

		const addformdata = { user_name: formValues.user_name.value, password: formValues.password.value }

		serviceConfig[actionType === "Edit" ? "put" : "post"]("manageGst", true, { ...addformdata, id: actionType === "Edit" ? editId : '' }, false, false, true)
			.then((res: any) => {
				setAction(true)
				if (res.id) {
					setProgres({ data: { loading: false, label: '' } })
					// getFileUploadStatus()
					setFormloading(false)
					setNotifyType("success")
					setNotifyMessage(actionType === "Edit" ? "GST details updated successfully." : "GST details added successfully.")
					setNotifyOpen(true)
					manageGstTable.current.setTableQuery({})
					loadGst()
					handleInitial()
					setAddGstLoadModal(false)
					fetchData()
					setPasswordType('password')
				}
				if (res === "GST data updated successfully.") {
					setProgres({ data: { loading: false, label: '' } })
					setFormloading(false)
					setNotifyType("success")
					setNotifyMessage(actionType === "Edit" ? "GST details updated successfully." : "GST details added successfully.")
					setNotifyOpen(true)
					manageGstTable.current.setTableQuery({})
					handleInitial()
					loadGst()
					setAddGstLoadModal(false)
					fetchData()
					setPasswordType('password')
				}
				else {
					if (res.message) {
						setProgres({ data: { loading: false, label: '' } })
						const { msg, ...rest } = res.data;
						setOtpres(rest);
						setFormloading(false)
						setNotifyType("warning")
						setNotifyMessage("Enter your OTP.")
						setNotifyOpen(true)
						setAddGstLoadModal(false)
						fetchData()
						{ actionType === "add" && setOtpmodal(true) }
					}
				}
			}).catch((err: any) => {
				setProgres({ data: { loading: false, label: '' } })
				console.log(err, 'error accured')
				setNotifyType("error")
				if (err.response && err.response.data) {
					setNotifyMessage(err.response.data);
				} else {
					setNotifyMessage("An error occurred");
				}
				setNotifyOpen(true)
				setFormloading(false)
				setAddGstLoadModal(false)
				setPasswordType('password')
			})
		handleInitial()
	}

	// add gst with OTP function
	const OTPpost = () => {
		setFormloading(true)

		setAddGstLoadModal(true)
		setOtpmodal(false)

		serviceConfig["post"]("manageGstotp", true, { ...otpres, otp: `{\"otp\":\"${otp.value}\"}` }, false, false, true)
			.then((res: any) => {
				setFormloading(false)
				setNotifyType("success")
				setNotifyMessage("GST details added successfully.")
				setNotifyOpen(true)
				manageGstTable.current.setTableQuery({})
				loadGst()
				setOtpres(null)
				setAddGstLoadModal(false)
			})
			.catch((err: any) => {
				setFormloading(false)
				setNotifyType("error")
				if (err.response && err.response.data) {
					setNotifyMessage(err.response.data);
				} else {
					setNotifyMessage("An error occurred");
				}
				setNotifyOpen(false)
				setAddGstLoadModal(false)
			})
	}

	// ...........

	// form modal function
	const handlecloseuploadpopup = () => {
		setModal3Open(false)
		setFormloading(false)
		setUploadedFile(null)
		setTabactive('form');
	}

	const handleAction = (type?: string, data?: any) => {
		setActionType(type || 'add')
		if (type === 'Edit') {
			setSelGST(data.gst_no)
			setEditId(data.id)
			handleInitial();
			Object.keys(data).forEach((key) => {
				if (formValues[key]) {
					handleChange({
						target: { type: 'text', name: key, value: data[key] },
					} as React.ChangeEvent<HTMLInputElement>);
				}
			});
		}
		setModal2Open(true)
	}
	// ............

	//.manage gst multiple add gst
	const handleUpload = (info: any) => {
		setUploadedFile(info.file.originFileObj)

		if (info.file.status === 'done') {
			setUploadedFile(info.file)
		} else if (info.file.status === 'error') {
			console.log(`${info.file.name} file upload failed.`);
		}
	};

	const handleDragEnter = (e: React.DragEvent) => {
		e.preventDefault();
		const files: any = e.dataTransfer.files[0];
		// setUploadedFile(files)
		if (files && files.length > 0) {

			if (files.type === 'application/vnd.ms-excel' || files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				setUploadedFile(files);
			} else {
				setNotifyOpen(true)
				setNotifyType("error")
				setNotifyMessage('Please select a valid csv or xslx file.');
				// alert('Please select a valid csv or xslx file.');
				setUploadedFile(null)
			}
		} else {
			setUploadedFile(null);
		}
	};

	const handleDragLeave = () => {
	};

	const handleDrop = (e: React.DragEvent) => {
		e.preventDefault();

		const files: any = e.dataTransfer.files[0];
		// setUploadedFile(files)
		if (files && files.length > 0) {

			if (files.type === 'application/vnd.ms-excel' || files.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
				setUploadedFile(files);
			} else {
				setNotifyOpen(true)
				setNotifyType("error")
				setNotifyMessage('Please select a valid csv or xslx file.');
				// alert('Please select a valid csv or xslx file.');
				setUploadedFile(null)
			}
		} else {
			setUploadedFile(null);
		}
	};

	const areAllValuesEqual = (array: any, key: any) => array.every((obj: { [x: string]: any; }) => obj[key] === "Completed");

	const getFileUploadStatus = () => {
		setUploading(true)
		var start_autoload: any = setInterval(() => {
			serviceConfig.get('gst_upload_file_status', true, null, null)
				.then((data: any) => {
					if (data && data.length > 0 && data[0].is_completed) {
						clearInterval(start_autoload)
						start_autoload = null;
						setProgres({ data: { loading: false, label: '' } })
						loadGst()
						setUploadcomplete(data[0].is_completed)
						setDetailsource(data)
						handletabClick('table')
						setUploading(false)
						setModal3Open(true);
					}
				}, (err: any) => {
					clearInterval(start_autoload)
					start_autoload = null;
					setProgres({ data: { loading: false, label: '' } })
					setUploading(false)
				})
		}, 10000);
	}

	const UploadFile = () => {
		setFormloading(true)
		let file_data = uploadedfile.hasOwnProperty('originFileObj') ? uploadedfile.originFileObj : uploadedfile
		serviceConfig.upload("upload_file", null, file_data)
			.then((data: any) => {
				setFormloading(false)
				setNotifyOpen(true)
				setNotifyType(data.data)
				setNotifyMessage(data.message);
				setUploadedFile(null)
				setModal3Open(false)
				setUploadedFile(null)
				openMessage()
				setProgres({ data: { loading: true, label: 'Upload' } })
				getFileUploadStatus()
				fetchData()
			}).catch((err: any) => {
				setUploadedFile(null)
				setFormloading(false)
				setNotifyOpen(true)
				setNotifyType("error")
				setNotifyMessage(err.response.data);
			})
	}

	const handletabClick = (label: any) => {
		setTabactive(label);
	}
	// ..............

	// delete data function
	const handleClickdelete = (id: any) => {
		setSelGST(id.gst_no)
		setEditId(id.id)
		setDeleteact(true);
	}

	const handlemultipledelete = (data?: any) => {
		const idsArray: string | null = Array.isArray(data) ? data.map((item: any) => item.id).join(',') : null;
		setSelectedgst(idsArray)
		setMultideleteact(true);
	}

	const handleDelete = (data?: any) => {

		const idToDelete = data || editId;

		setFormloading(true)
		serviceConfig.delete("manageGst", true, { id: idToDelete }, false).then((res: any) => {
			setNotifyType("success")
			setNotifyMessage("GST details deleted successfully.")
			setNotifyOpen(true)
			setFormloading(false)
			setDeleteact(false)
			setMultideleteact(false)
			manageGstDataLoading.current = false;
			manageGstTable.current.setTableQuery({})
			loadGst()
			setAction(true)
			setSelectedgst('')
		}).catch((err: any) => {
			setNotifyType("error")
			setNotifyMessage(err.response.data)
			setNotifyOpen(true)
			setFormloading(false)
		})
	}
	// ............

	// sync function
	const syncProgress = useRef(false)
	let [syncId, setSyncId] = useState<any>(null)
	const synccallfunction = (selectedGST?: any): Promise<any> => {
		console.log(selectedGST)
		return new Promise((resolve, reject) => {
			handlesync(selectedGST)
				.then((data: any) => {
					setIsSyncAllProgress(data.isSyncAll)
					syncId = data.data.syncId
					setSyncId(data.data.syncId)
					setGstSyncModal(true)
					setTimeout(() => setGstSyncModal(false), 40000)
					syncProgress.current = false;
					getStatusofSyncAllProgress()
					loadGst()
					resolve(data);
				})
				.catch((err: any) => {
					setNotifyType("error")
					setNotifyMessage(err.response.message)
					setNotifyOpen(true)
					reject(err);
					syncProgress.current = false;
				});
		})
	}

	// get last sync details of user
	const getLastSyncStatus = () => {
		serviceConfig.get("gst_sync", true, { 'last': true }, null)
			.then((data: any) => {
				if (data.length > 0) {
					setSyncDetails(data[0])
					!data[0].sync_processed && setIsSyncAllProgress(true)
					// !data[0].sync_processed && setIsSyncAllProgress(data[0].is_sync_all)
					if (!data[0].sync_processed && data[0].is_sync_all) {
						syncId = data[0].id
						setSyncId(data[0].id)
						getStatusofSyncAllProgress()
					}
				}
			}, (err: any) => {
				console.log(err)
			})
	}


	const getStatusofSyncAllProgress = () => {
		setProgres({ data: { loading: true, label: 'Sync' } })
		serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
			setSyncDetails(data)
			setIsSyncAllProgress(!data.sync_processed ? true : false)
			// setIsSyncAllProgress((data.is_sync_all && !data.sync_processed) ? true : false)
			syncId = data.id
			setSyncId(data.id)
			let timerInt = !data.sync_processed ? setTimeout(() => {
				if (ismanageRef.current) {
					loadGst();
				}
				// { ismanage && loadGst() }
				getStatusofSyncAllProgress();
			}, (10 * 1000)) : null;
			if (data.sync_processed) {
				timerInt = null;
				setreportModal(true)
				syncProgress.current = false;
				if (ismanageRef.current) {
					loadGst();
				}
				// { ismanage && loadGst() }
				manageGstDataLoading.current = false;
				setProgres({ data: { loading: false, label: '' } })
			}
		}, (err: any) => {
			console.log(err)
			setProgres({ data: { loading: false, label: '' } })
		})
	}
	// ..............

	// message open function
	const openMessage = () => {
		messageApi.open({
			key,
			type: 'loading',
			content: 'Loading...',
		});
		setTimeout(() => {
			messageApi.open({
				key,
				type: 'success',
				content: 'Add Multiple GSTIN sync started successfully!',
				duration: 2,
			});
		}, 1000);
	}
	// ..............

	// demo video popup
	const getYouTubeVideoId = (url: any) => {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
		const match = url.match(regExp);

		if (match && match[2].length === 11) {
			return match[2];
		} else {
			// Handle invalid URL or ID not found
			return 'Invalid YouTube URL';
		}
	}

	const videourl = 'https://www.youtube.com/watch?v=MEdjUBWtpgM&t=12s';
	const videoId = getYouTubeVideoId(videourl);

	const opts = {
		height: '500px',
		width: '100%',
		playerVars: {
			autoplay: 1,
		},
	};

	// Function to close the modal and stop the video
	const closeModal = () => {
		if (playerRef.current) {
			// If the player exists, stop the video
			playerRef.current.internalPlayer.stopVideo();
		}
		setVideopopup(false); // Close the modal
	};
	// .............

	useEffect(() => {
		const storedUserRole = localStorage.getItem('userRole');

		loadGst()
		if (storedUserRole !== 'Super Admin') {
			getLastSyncStatus()
		}
	}, [loadGst])

	useEffect(() => {
		ismanageRef.current = ismanage;
		setIsmanage(ismanagegst)
	}, [ismanagegst, ismanage])

	const uploadfunction = () => {
		setModal3Open(true);
	}
	// ...............

	// button details props
	const buttondetails = [
		// {
		// 	type: 'upload',
		// 	name: 'Multiple GSTIN',
		// 	theme: 'theme1',
		// 	conditions: ['if_list'],
		// 	function: uploadfunction
		// },
		// {
		// 	type: 'add',
		// 	name: 'Add GSTIN',
		// 	theme: 'theme1',
		// 	conditions: ['if_list'],
		// 	function: handleAction
		// },
		{
			type: 'download',
			name: 'Download',
			theme: 'theme4',
			conditions: ['if_list'],
			downloadquery: 'manage_gst'
		},
		// {
		// 	type: 'syncall',
		// 	name: 'Sync All',
		// 	theme: 'theme3',
		// 	conditions: ['if_list', isSyncAllProgress],
		// 	function: synccallfunction
		// }

	]
	// ...............

	// table columns and filter requirement props
	const columns: any = [
		{
			title: 'GSTIN',
			dataIndex: 'gst_no',
			filters: masterData.gst
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}),
			filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'gst_no' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: 'State',
			dataIndex: 'state',
			filters: masterData.states
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}),
			filterIcon: () => filterQuery && filterQuery.state ? < img src={active_filtericon} alt='filter_active_icon' /> : < img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'state' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: 'Trade Name',
			dataIndex: 'trade_name',
			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}),
			filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,

			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: "User ID",
			dataIndex: 'user_name',
			filters: masterData.userNames ? masterData.userNames
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}) : [],
			filterIcon: () => filterQuery && filterQuery.user_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'user_name' ? sorterQuery.order : 'ascend',
		},
		{
			title: "Passsword",
			dataIndex: "password",
			render(value: any, record: any, index: any) {
				if (!record.wrong_password) {
					return <img src={passwordDotIcon} alt='password icon' />
				}
				else {
					return <Tooltip title={'Given credentials mismatched!'}><img src={wrongPswdIcon} alt='password icon' /></Tooltip>
				}

				// return (
				// 	<>
				// 		<img src={record.wrong_password ? wrongPswdIcon : passwordDotIcon} alt='password icon' />
				// 	</>
				// )
			}
		},
		{
			title: "Last Sync",
			// dataIndex:"last_sync",
			dataIndex: 'last_sync',
			render(value: any, record: any, index: any) {
				function join(date: any, options: any, separator: any) {
					function format(option: any) {
						let formatter = new Intl.DateTimeFormat('en', option);
						return formatter.format(date);
					}
					let joined = options.map(format).join(separator);
					return joined + ', ' + new Date(value).toLocaleTimeString();
				}

				let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
				return value ? join(new Date(value), options, ' ') : '--';
			},
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'last_sync' ? sorterQuery.order : 'ascend',
		},
		{
			title: "Remarks",
			dataIndex: 'sync_status',
			render(value: any, record: any, index: any) {
				if (value === "Not sync") {
					return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px' }} >{value}</Tag>
				}
				if (value === "Sync initiated") {
					return <Tooltip><Tag style={{ borderRadius: "25px", padding: '3px 10px', color: '#0d497f', background: '#a3c9eb', borderColor: '#0d497f' }} >{value}</Tag></Tooltip>
				}
				if (value === "Sync failed") {
					return <Tooltip title={record.failed_reason}><Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px' }} >{value}</Tag></Tooltip>
				}
				if (value === "Syncing") {
					return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px' }} >{value}</Tag>
				}
				if (value === "Synced") {
					return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px' }} >{value}</Tag>
				}
			},
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'sync_status' ? sorterQuery.order : 'ascend',
		},
		{
			title: "Action",
			dataIndex: "sync_status",
			fixed: "right",
			render(value: any, record: any, index: any) {
				return (
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} ><img src={editIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => {
						handleAction("Edit", record)
						console.log(record)
					}
					} /> | <img src={deleteIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => handleClickdelete(record)} /> </div>
				)
			}
		},
	];
	const incorrectcolumns: any = [
		{
			title: 'GSTIN',
			dataIndex: 'gst_no',
			filters: masterData.gst
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}),
			filterIcon: () => filterQuery && filterQuery.gst_no ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'gst_no' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: 'Trade Name',
			dataIndex: 'trade_name',
			filters: masterData.trades
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}),
			filterIcon: () => filterQuery && filterQuery.trade_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,

			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'trade_name' ? sorterQuery.order : 'ascend',
			render(value: any, record: any, index: any) {
				return value ? value : '--'
			},
		},
		{
			title: "User ID",
			dataIndex: 'user_name',
			filters: masterData.userNames ? masterData.userNames
				.filter((item: any) => item !== null && item.trim() !== "")
				.map((item: any) => {
					return {
						text: item,
						value: item,
					};
				}) : [],
			filterIcon: () => filterQuery && filterQuery.user_name ? < img src={active_filtericon} alt='filter_active_icon' /> : <img src={filterIcon} alt='filter icon' />,
			// filterDropdownOpen: gstList.length > 0,
			filterSearch: true,
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'user_name' ? sorterQuery.order : 'ascend',
		},
		{
			title: "Passsword",
			dataIndex: "password",
			render(value: any, record: any, index: any) {
				if (!record.wrong_password) {
					return <img src={passwordDotIcon} alt='password icon' />
				}
				else {
					return <Tooltip title={'Given credentials mismatched!'}><img src={wrongPswdIcon} alt='password icon' /></Tooltip>
				}

				// return (
				// 	<>
				// 		<img src={record.wrong_password ? wrongPswdIcon : passwordDotIcon} alt='password icon' />
				// 	</>
				// )
			}
		},
		{
			title: "Last Sync",
			// dataIndex:"last_sync",
			dataIndex: 'last_sync',
			render(value: any, record: any, index: any) {
				function join(date: any, options: any, separator: any) {
					function format(option: any) {
						let formatter = new Intl.DateTimeFormat('en', option);
						return formatter.format(date);
					}
					let joined = options.map(format).join(separator);
					return joined + ', ' + new Date(value).toLocaleTimeString();
				}

				let options = [{ day: 'numeric' }, { month: 'short' }, { year: 'numeric' }];
				return value ? join(new Date(value), options, ' ') : '--';
			},
			sorter: true,
			sortDirection: sorterQuery.columnKey === 'last_sync' ? sorterQuery.order : 'ascend',
		},
		{
			title: "Action",
			dataIndex: "sync_status",
			fixed: "right",
			render(value: any, record: any, index: any) {
				return (
					<div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} ><img src={editIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => {
						handleAction("Edit", record)
						console.log(record)
					}
					} /> | <img src={deleteIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => handleClickdelete(record)} /> </div>
				)
			}
		},
	];

	const detailcolumns = [
		{
			title: 'S.No',
			dataIndex: 'serial',
			key: 'serial',
			render: (text: any, record: any, index: number) => index + 1,
		},
		{
			title: 'User Name',
			dataIndex: 'user_name',
			key: 'user_name',
		},
		{
			title: 'Password',
			dataIndex: 'password',
			key: 'password',
			render(value: any, record: any, index: any) {
				if (!record.wrong_password) {
					return <img src={passwordDotIcon} alt='password icon' />
				}
				else {
					return <Tooltip title={'Given credentials mismatched!'}><img src={wrongPswdIcon} alt='password icon' /></Tooltip>
				}

				// return (
				// 	<>
				// 		<img src={record.wrong_password ? wrongPswdIcon : passwordDotIcon} alt='password icon' />
				// 	</>
				// )
			}
		},
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
		},
	];
	// .............

	return (
		<div className="manage-gst-container">
			<ZTable2 label={'Manage GSTIN'} button={buttondetails} syncprogress={isSyncAllProgress} columns={incorrectcolumns} expand={false} ref={manageGstTable} tabledata={gstList} loading={loading} totalpages={totalpages} handleDelete={handlemultipledelete} />
			<Modal
				title={"How to"}
				className='video-popup'
				centered
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
				open={videopopup}
				onOk={closeModal}
				onCancel={closeModal}
				style={{ height: 'auto', width: 'max-content', color: "#031742" }}
			>
				<YouTube videoId={videoId} opts={opts} onEnd={closeModal} ref={playerRef} />
			</Modal>
			<Modal
				title={""}
				onCancel={() => setGstSyncModal(false)}
				width={'35%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={gstSyncModal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div>
					<b>Sync scheduled successfully</b>
					<p>You can minimize this tab or even close the website, and return later to check the process. You're all set!</p>
				</div>
			</Modal>

			<Modal
				title={""}
				onCancel={() => setAddGstLoadModal(false)}
				width={'35%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={addGstLoadModal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div>
					<b>{actionType === "Edit" ? "Updating" : "Adding"} GSTIN in progress</b>
					<p>{actionType === "Edit" ? "Updating" : "Adding"} GSTIN may take 30 seconds. Please wait. You may close this dialog box.</p>
				</div>
			</Modal>
			<Modal
				onCancel={() => setMultideleteact(false)}
				centered
				closeIcon={false}
				className='delete-gst'
				style={{ textAlign: "center", color: "#031742", top: '-14%' }}
				open={multideleteact}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<img src={deleteConfirmIcon} alt='action' />
					<h2 className="del-header">Are you sure want to delete this selected GSTIN'S</h2>
					<Button htmlType="submit" style={{ background: "none", color: "#ed7d31", border: "0.5px solid #ed7d31", width: "30%", height: "5vh", fontFamily: 'Inter' }}
						onClick={() => {
							setSelectedgst('');
							setMultideleteact(false);
						}}>CANCEL</Button>
					<Button htmlType="submit" onClick={() => { handleDelete(selectedgst) }} loading={formloading} style={{ background: "#424B70", color: "white", border: "none", width: "30%", height: "5vh", marginLeft: "10%", fontFamily: 'Inter' }}  >DELETE</Button>
				</Form>
			</Modal>
			<Modal
				onCancel={() => setDeleteact(false)}
				centered
				closeIcon={false}
				className='delete-gst'
				style={{ textAlign: "center", color: "#031742", top: '-14%' }}
				open={deleteact}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<img src={deleteConfirmIcon} alt='action' />
					<h2 className="del-header">Are you sure want to delete this GSTIN</h2>
					<span style={{ textAlign: "right", display: "none" }}>This field is required</span>
					<p className="gstin-num">GSTIN : {sel_gst_no}</p>
					<Button htmlType="submit" style={{ background: "none", color: "#ed7d31", border: "0.5px solid #ed7d31", width: "30%", height: "5vh", fontFamily: 'Inter' }} onClick={() => {
						setDeleteact(false)
					}}>CANCEL</Button>
					<Button htmlType="submit" onClick={handleDelete} loading={formloading} style={{ background: "#424B70", color: "white", border: "none", width: "30%", height: "5vh", marginLeft: "10%", fontFamily: 'Inter' }}  >DELETE</Button>
				</Form>
			</Modal>
			<Modal
				title={actionType === 'add' ? "ADD GSTIN" : `EDIT: ${sel_gst_no}`}
				onCancel={() => { setModal2Open(false); setFormloading(false); handleInitial(); setPasswordType('password') }}
				width={'30%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={modal2Open}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>

				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
						<label className="label">User id</label>
						<div className="input-box" >
							<img className="icons" src={usernameIcon} alt='usernameIcon'></img>
							<input
								type="text"
								name='user_name'
								value={user_name.value}
								onBlur={handleChange}
								onChange={handleChange}
								placeholder="Enter your portal user id"></input>
							{user_name.error && <p className="gst-err-msg" >{user_name.error}</p>}
						</div>
					</div>
					<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
						<label className="label">Password</label>
						<div className="input-box" >
							<img className="icons" src={passwordIcon} alt='password_icon'></img>
							<input
								type={password_type}
								name='password'
								value={password.value}
								onBlur={handleChange}
								onChange={handleChange}
								placeholder="Enter your password"></input>
							{password_type === 'password' ? <VisibilityOutlinedIcon onClick={() => setPasswordType('text')} className='eye_icon' /> : <VisibilityOffOutlinedIcon onClick={() => setPasswordType('password')} className='eye_icon' />}
							{password.error && <p className="gst-err-msg">{password.error}</p>}
						</div>
					</div>

					<Button
						htmlType="submit"
						className='popup-button'
						loading={formloading}
						disabled={isError()}
						style={{ background: "#424B70", color: "white", border: "none", width: "80%", height: "6vh", marginTop: password.error ? '' : '1.5rem', left: '10%' }}
						onClick={() => {
							actionGst()
						}}>
						{!formloading && <>{actionType === 'add' ? 'Add' : 'Update'} & GET DETAILS FROM GSTIN</>}
						{formloading && <>Loading...</>}
					</Button>

				</Form>
			</Modal>
			<Modal
				title="Multiple GSTIN"
				onCancel={handlecloseuploadpopup}
				className='upload-popup'
				width={'auto'}
				centered
				style={{ color: "#031742", minWidth: '30%' }}
				open={modal3Open}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<div className="detail-view-tab-cover-container">
					<div className='detail-view-tab-cover'>
						<span className={tabactive === 'form' ? 'view-tab one active-tab' : 'view-tab one'} onClick={() => handletabClick('form')}>Upload</span>
						<span className={tabactive === 'table' ? 'view-tab two active-tab' : 'view-tab two'} onClick={() => { handletabClick('table'); getFileUploadStatus() }}>Details</span>
					</div>
				</div>
				{tabactive === 'form' ?
					<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">

						<Upload
							action="/your-upload-api-endpoint"
							onChange={handleUpload}
							style={{ width: '100%' }}
							accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
							showUploadList={false} // Hide the list of uploaded files
						>
							<div
								className='upload_box'
								onDragEnter={handleDragEnter}
								onDragOver={handleDragEnter}
								onDragLeave={handleDragLeave}
								onDrop={handleDrop}>
								<input
									type="file"
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
									style={{ display: 'none' }}
									onChange={handleUpload}
								/>
								{uploadedfile ?
									<p className='selected-file-name'>
										<b>Selected File: </b>{uploadedfile.name}</p> :
									<p>
										Drag & Drop files here or click to select<br></br>
										(CSV or XLSX (.csv or .xlsx)) file only allowed)
									</p>}
							</div>
						</Upload>

						<div className='downlod-template-wrap'>
							<p className='download-link' onClick={() => downloadServices.gst_template()}>Download Template</p>
						</div>

						<Form.Item>
							<Button
								htmlType="submit"
								className='popup-button upload-pop-btn'
								loading={formloading}
								disabled={!uploadedfile || !uploadcomplete}
								style={{
									background: "#424B70",
									color: "white",
									border: "none",
									width: "80%",
									height: "6vh",
									marginTop: '1.5rem',
									left: '10%'
								}}
								onClick={() => {
									UploadFile()
								}}>
								{!formloading && <>{uploadcomplete ? 'Upload' : 'Upload Inprogressing'}</>}
								{formloading && <>Loading...</>}
							</Button>
						</Form.Item>

					</Form> :
					<>
						<div className='table-label-wrap'>
							<div className='table-label'>
								<span>File name</span>
								<h4>{detailSource.length > 0 ? detailSource[0].file_name : '-'}</h4>
							</div>
							<div className='table-label'>
								<span>Total count</span>
								<h4>{detailSource.length > 0 ? detailSource[0].total_count : '-'}</h4>
							</div>
							<div className='table-label'>
								<span>Success count</span>
								<h4>{detailSource.length > 0 ? detailSource[0].success_count : '-'}</h4>
							</div>
							<div className='table-label'>
								<span>Error count</span>
								<h4>{detailSource.length > 0 ? detailSource[0].error_count : '-'}</h4>
							</div>
						</div>
						<Table
							className='details-table'
							loading={uploading}
							style={{ minWidth: '35vw', maxWidth: '100%', marginTop: '3%' }}
							columns={detailcolumns}
							dataSource={detailSource.length > 0 ? detailSource[0]?.gst_list.filter((it: any) => it.is_error) : []}
						// Other props for Table component
						/>
					</>
				}
			</Modal >
			<Modal
				title="OTP Form"
				onCancel={() => setOtpmodal(false)}
				width={'35%'}
				className='action-popup'
				centered
				style={{ color: "#031742" }}
				open={otpmodal}
				okButtonProps={{ style: { display: 'none' } }}
				cancelButtonProps={{ style: { display: 'none' } }}
			>
				<Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
					<div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
						<div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
							<label className="label">OTP</label>
							{otp.error && <p style={{ margin: '0', marginLeft: 'auto' }} className='gst-err-msg'>{otp.error}</p>}
						</div>
						<div className="input-box" >
							<input
								type="text"
								name='otp'
								value={otp.value}
								onBlur={handleChange}
								onChange={handleChange}
								placeholder="Enter OTP"></input>
						</div>
					</div>
					<Form.Item>
						<Button
							htmlType="submit"
							disabled={otp.error ? true : false}
							className='popup-button'
							loading={formloading}
							style={{ background: "#424B70", color: "white", border: "none", width: "80%", height: "6vh", 'marginTop': '1.5rem', left: '10%' }}
							onClick={() => {
								OTPpost()
							}}>
							{!formloading && <>Submit</>}
							{formloading && <>Loading...</>}
						</Button>
					</Form.Item>
				</Form>
			</Modal>
			{/* .................. */}

			<NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
		</div >
	)
}