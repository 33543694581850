// react
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// scss file
import './allnotice.scss';

// package components

// child components
import { NotifyAlert } from "../../components/notify_alert";
import Breadcrumbs from "../../shared/Breadcrumb";
import { useGlobalState } from '../../Statecontext';
import { Subnotice } from '../Subnotice';

// icons

export const Allnotice = () => {

    //  datas states
    const [filterList, setFilterList] = useState<any>([])
    const [gstList, setGstList] = useState<any>([])
    const [noticeObj, setNoticeObj] = useState(false);
    const { menuclick, setMenuclick, rowsPerPage, page, noticeQuery, setPage, setSorterQuery, setTotalpages, tabactive, globalsearch, manageactive, breadcrumbPath, setBreadcrumbPath, selectgst, setSelectgst, progres, ismanagegst, setreportModal, setSyncDetails, setGstSyncModal, masterData, setMasterData, setProgres, searchquery, setSearchquery, setNoticeQuery, setChangeview, sorterQuery, setTabactive, chartquery, setChartquery, filterQuery, setFilterQuery, overviewDetails } = useGlobalState();

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')

    // loading state
    const [loading, setLoading] = useState(false);

    // limit & filter states

    // reference state
    const navigate = useNavigate()
    const abortControllerRef = useRef<AbortController | null>(null);

    // search option function
    const searchclick = (value: any) => {
        setPage(1)
        const filterTable = gstList.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setFilterList(filterTable);
        if (filterTable.length > 0) {
            setSelectgst(filterTable[0])
            let query: any = {
                gst_no: [filterTable[0].gst_no]
            };
            setBreadcrumbPath((prevPath: any) => {
                const pathSegments = prevPath.split('-').filter(Boolean); // Split the path by '/' and remove empty segments
                pathSegments.pop(); // Remove the last segment
                return `${pathSegments.join('/')} - ${filterTable[0].gst_no || filterTable[0].user_name}`; // Join remaining segments and append the new gst_no
            });
            setFilterQuery(query);
        }
    };

    const menu_itms: any = [
        {
            name: 'Latest notices'
        },
        {
            name: 'Action taken'
        },
        {
            name: 'Action to be taken'
        },
        {
            name: 'Overdue'
        },
        {
            name: 'All notices'
        }
    ]
    // ........
    const menuClickhandler = (item: any) => {
        setMenuclick(item.name)
        navigate('/home/allnotice')
        if (item.name === 'All notices') {
            setTabactive('Additional')
            let updatedQuery: any = {}
            updatedQuery['is_over_due'] = false
            setNoticeQuery(updatedQuery)
            setBreadcrumbPath(`home/notices/all notices`);
        } else if (item.name === 'Latest notices') {
            setTabactive('Latest')
            let updatedQuery: any = {}
            updatedQuery['is_over_due'] = false
            setNoticeQuery(updatedQuery)
            setBreadcrumbPath(`home/notices/${item.name}`);
        } else if (item.name === 'Action to be taken' || item.name === 'Action taken') {
            setTabactive('Additional')
            let updatedQuery: any = {}
            updatedQuery['notice_status'] = [item.name]
            updatedQuery['is_over_due'] = false
            setNoticeQuery(updatedQuery)
            setBreadcrumbPath(`home/notices/${item.name}`);
        } else if (item.name === 'Overdue') {
            setTabactive('Additional')
            let updatedQuery: any = {}
            updatedQuery['is_over_due'] = true
            setNoticeQuery(updatedQuery)
            setBreadcrumbPath(`home/notices/${item.name}`);
        }
    }
    return (
        <>
            <div id="allnotice-details-container" className='view-gst-info'>
                <div className='overview-menu-wrap'>
                    <div className='list_wrap'>
                        {/* <div className='search_cover'>
                            <Input
                                className='z-table_global_search'
                                value={localsearch}
                                onChange={handleGlobalSearchChange}
                                placeholder='Type here to search...'
                                suffix={<img
                                    src={searchIcon}
                                    onClick={() => searchclick(localsearch)}
                                    style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                    alt='password icon' />}
                            />
                            <Tooltip placement="right" title={'Add GSTIN'} arrow={true} color={'#424B70'} >
                                <Buttons className='gst_add_btn' onClick={() => { handleAction() }}>
                                    <img src={plusIcon} alt='plus icon' />
                                </Buttons>
                            </Tooltip>
                        </div> */}
                        <div className='list_item_wrap'>
                            {menu_itms.map((menu: any) => (
                                <div className={menuclick === menu.name ? 'menu_block menu_active' : 'menu_block'} key={menu} onClick={() => { menuClickhandler(menu) }}>
                                    {menu.name}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="overview-upper-container" style={{ height: "100%" }}>
                    <div className="overview-notice-main">
                        <div className='overview_menu_wrap'>
                            <Breadcrumbs />
                        </div>
                        <Subnotice />
                    </div>
                </div>

            </div >
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
        </>
    )
}