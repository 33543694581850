// react 
import { useCallback, useContext, useEffect, useState } from 'react';
// ..........

// scss file
import './latestupdates.scss';
// ..........

// package components
import { Button, Form, Input, Modal, Spin } from 'antd';
// ..........

// child components
import { AppContext } from '../../core/utils/Context';
import { useGlobalState } from '../../Statecontext'
import { ZTable2 } from '../../components/table2';
import { serviceConfig } from '../../core/services';
// ..........

// icons
import searchIcon from '../../assets/images/search.svg';
import SubjectIcon from '@mui/icons-material/Subject';
import usernameIcon from '../../assets/images/username.svg'
import { NotifyAlert } from '../../components/notify_alert';
import deleteIcon from '../../assets/images/deleteIcon.svg';
import editIcon from '../../assets/images/editIcon.svg'
import deleteConfirmIcon from '../../assets/images/deleteConfirm.svg'
import Breadcrumbs from '../../shared/Breadcrumb';
import plusIcon from '../../assets/images/plus.svg';
import EditIcon from '@mui/icons-material/Edit';
import ArticleIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import { TextField } from '@mui/material';
// ..........

export const Latestupdates = () => {

    // loading state
    const [loading, setLoading] = useState(false)
    const [folderloading, setFolderloading] = useState(false)
    const [articleloading, setArticleloading] = useState(false)
    const [formloading, setFormloading] = useState(false);
    // ..........

    // data states
    const userRole = localStorage.getItem('userRole') || '';
    const [updatesList, setUpdateslist] = useState<any>([])
    const [noticeData, setNoticeData] = useState<any>([])
    const [sel_update, setSelupdate] = useState<any>('')
    const [selectedupdate, setSelectedupdate] = useState<any>()
    const [localsearch, setLocalsearch] = useState('')
    const [folders, setFolders] = useState([])
    const [articles, setArticles] = useState<any>([])
    const [subtab, setSubtab] = useState(() => {
        const savedata = localStorage.getItem('updatetab');
        return savedata ? JSON.parse(savedata) :
            'Latest Updates'
    })
    const [selfolder, setFolder] = useState<any>({})
    const [selarticle, setSelatricle] = useState<any>({})
    const Typooftags = [
        { id: 1, value: 'Latest Updates' },
        { id: 2, value: 'Announcement' },
        { id: 3, value: 'Success Stories' },
    ]
    // ..........

    // form values states
    const [noticeObj, setNoticeObj] = useState(false);
    const { formValues, handleChange, handleInitial } = useContext(AppContext)
    const { update_subject, update_desc, update_link, tags } = formValues;
    const { setBreadcrumbPath, totalpages, setTotalpages, page, rowsPerPage, searchquery, setSearchquery, setPage } = useGlobalState();
    const [actionType, setActionType] = useState("")
    const [editId, setEditId] = useState('')
    // ..........

    // alerts and modal states
    const [latestmodal, setLatestmodal] = useState(false);
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    const [deleteact, setDeleteact] = useState(false)
    const [multideleteact, setMultideleteact] = useState(false);
    // ..........

    // reference states
    const [form] = Form.useForm();
    const userData = JSON.parse(localStorage.getItem('userData') || '{}')
    // ..........

    // get call for details
    const get_latest_updates = useCallback(() => {
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage
        }

        if (searchquery) {
            query['search'] = searchquery;
        }

        if (subtab !== 'Knowledge Bank') {
            query['tags'] = subtab
        }

        setLoading(true)
        serviceConfig.get("latest_updates", true, query, null).then((data: any) => {
            setLoading(false)
            setUpdateslist(data.results || data)
            setNoticeData(data.results || data)
        }, (err: any) => {
            setLoading(false)
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
        })
    }, [page, rowsPerPage, searchquery, subtab])

    const get_knowledge_category = useCallback(() => {
        let query: any = {}
        setFolderloading(true)
        serviceConfig.get("knowledge_category_folder", true, query, null).then((data: any) => {
            setFolderloading(false)
            setFolders(data.folders)
            setFolder(data.folders[0])
            get_article(data.folders[0].id)
        }, (err: any) => {
            setFolderloading(false)
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
        })
    }, [])

    const get_article = useCallback((id?: any) => {
        setLoading(true)
        const idToUse = id || selfolder.id;
        serviceConfig.get("knowledge_category_article", true, null, { id: idToUse }, false)
            .then((data: any) => {
                setLoading(false)
                setArticles(data.articles)
            }, (err: any) => {
                setLoading(false)
                setNotifyType("error")
                setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                setNotifyOpen(true)
            })
    }, [])

    const get_particular_article = useCallback((id?: any) => {
        setLoading(true)
        const idToUse = id || selarticle.id;
        serviceConfig.get("knowledge_by_article", true, null, { id: idToUse }, false)
            .then((data: any) => {
                setLoading(false)
            }, (err: any) => {
                setLoading(false)
                setNotifyType("error")
                setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
                setNotifyOpen(true)
            })
    }, [])

    useEffect(() => {
        get_latest_updates()
        subtab === 'Knowledge Bank' && get_knowledge_category()
    }, [subtab])

    // useEffect(() => {
    //     selfolder.id && get_article()
    // }, [selfolder])

    useEffect(() => {
        if (subtab === 'Latest Updates') {
            setBreadcrumbPath(`home/services/latest updates`);
        } else {
            setBreadcrumbPath(`home/services/latest updates/${subtab}`);
        }
        localStorage.setItem('updatetab', JSON.stringify(subtab));
    }, [subtab])

    // Check if all values are not empty and if there are some errors
    const isError = useCallback(
        () =>
            Object.keys({ update_subject, update_desc, update_link, tags }).some(
                (name) => (formValues[name].required && !formValues[name].value) || formValues[name].error
            ),
        [formValues, update_subject, update_desc, update_link, tags]
    )
    // .......

    // add update fuction
    const apiToFormFieldMapping: { [key: string]: string } = {
        subject: 'update_subject',
        description: 'update_desc',
        link: 'update_link'
    }
    const handleAction = (type?: string, data?: any) => {
        setActionType(type || 'add')
        if (type === 'Edit') {
            setSelupdate(data.gst_no)
            setEditId(data.id)
            handleInitial();
            // Object.keys(data).forEach((key) => {
            //     if (formValues[key]) {
            //         handleChange({
            //             target: { type: 'text', name: key, value: data[key] },
            //         } as React.ChangeEvent<HTMLInputElement>);
            //     }
            // });
            Object.keys(data).forEach((apiKey) => {
                const formKey = apiToFormFieldMapping[apiKey]; // Get the mapped form field name
                if (formKey && formValues[formKey] !== undefined) {
                    handleChange({
                        target: { type: 'text', name: formKey, value: data[apiKey] },
                    } as React.ChangeEvent<HTMLInputElement>);
                }
            });
        }
        setLatestmodal(true);
    }

    const Addupdates = () => {
        console.log(tags, 'tags find')
        let req_body = {
            'subject': update_subject.value,
            'description': update_desc.value,
            'link': update_link.value,
            'tags': tags.value,
            ...(actionType === "Edit" ? { 'id': editId } : { 'user': userData.id })
        }
        setFormloading(true)
        serviceConfig[actionType === "Edit" ? "put" : "post"]('latest_updates', true, req_body, null).then((res: any) => {
            setFormloading(false)
            handleInitial();
            setLatestmodal(false);
            setNotifyType("success")
            setNotifyMessage(`Latest updates details ${actionType === "Edit" ? "updated" : "submitted"} successfully !`)
            setNotifyOpen(true)
            get_latest_updates()
        }, (err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response && err.response.data || 'Something Went Wrong !')
            setNotifyOpen(true)
            setFormloading(false)
        })
    }
    // .........

    // delete data function
    const handleClickdelete = (id: any) => {
        setSelupdate(id.gst_no)
        setEditId(id.id)
        setDeleteact(true);
    }

    const handlemultipledelete = (data?: any) => {
        const idsArray: string | null = Array.isArray(data) ? data.map((item: any) => item.id).join(',') : null;
        setSelectedupdate(idsArray)
        setMultideleteact(true);
    }

    const handleDelete = (data?: any) => {

        const idToDelete = editId;

        setFormloading(true)
        serviceConfig.delete("latest_updates", true, { id: idToDelete }, false).then((res: any) => {
            setNotifyType("success")
            setNotifyMessage("Latest update details deleted successfully.")
            setNotifyOpen(true)
            setFormloading(false)
            setDeleteact(false)
            setMultideleteact(false)
            get_latest_updates()
            setSelectedupdate('')
        }).catch((err: any) => {
            setNotifyType("error")
            setNotifyMessage(err.response.data)
            setNotifyOpen(true)
            setFormloading(false)
        })
    }
    // ............


    // search 
    const search = (value: any) => {
        if (noticeData[0].notice) {
            setNoticeObj(true);
        }
        const filterTable = noticeData.filter((o: any) =>
            Object.keys(noticeObj ? o.notice : o).some(k => {
                if (k !== 'gst' && k !== 'case_data') {
                    return String(noticeObj ? o.notice[k] : o[k])
                        .toLowerCase()
                        .includes(value.toLowerCase())
                } else {
                    return Object.keys(noticeObj ? o.notice[k] : o[k]).some(j => String(noticeObj ? o.notice[k][j] : o[k][j])
                        .toLowerCase()
                        .includes(value.toLowerCase()))
                }
            }
            )
        );

        setUpdateslist(filterTable);
    };

    const handleGlobalSearchChange = (e: any) => {
        let value = e.target.value;

        if (!value) {
            setSearchquery(value)
            setLocalsearch(value)
            search(value);
        } else {
            setLocalsearch(value)
        }

    }
    const searchclick = (value: any) => {
        setPage(1)
        setSearchquery(value)
    }
    // ....

    // subcomponent 
    const header = () => {
        const actions = [
            {
                name: 'Latest Updates'
            }, {
                name: 'Announcement'
            }, {
                name: 'Success Stories'
            }, {
                name: 'Knowledge Bank'
            }
        ]

        const subtabclick = (action: any) => {
            setSubtab(action.name)
            if (action.name !== 'Knowledge Bank') {
                setFolder({})
                setSelatricle({})
                setArticles([])
                setFolders([])
                localStorage.removeItem('updatetab');
            }
        }
        return (
            <div className='latest_header'>
                <div className='first_row'>
                    <span>Latest Updates</span>
                    <div className='right_cover'>
                        <Input
                            className='search'
                            value={localsearch}
                            onChange={handleGlobalSearchChange}
                            placeholder='Type here to search...'
                            suffix={<img
                                src={searchIcon}
                                onClick={() => searchclick(localsearch)}
                                style={{ height: "80%", cursor: "pointer", borderRadius: 20 }}
                                alt='password icon' />}
                        />
                        {userRole === 'Super Admin' && <Button className='gst_add_btn' onClick={() => { handleAction() }}>
                            <img src={plusIcon} alt='plus icon' />
                        </Button>}
                    </div>
                </div>
                <div className='second_row'>
                    {actions.map((action: any) => (
                        <span className={subtab === action.name ? 'sub_action active' : 'sub_action '} key={action} onClick={() => { subtabclick(action) }}>
                            <span># </span> {action.name}
                        </span>
                    ))}
                </div>
            </div >
        )
    }

    const content = () => {
        const capitalizeFirstLetter = (str: string) => {
            if (!str) return '';
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
        };
        const capitalizeSubstring = (str: string) => {
            if (!str) return '';
            const capitalized = str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
            return capitalized.length > 36 ? capitalized.substring(0, 36) + '...' : capitalized;
        };
        return (
            <div className='content_wrap'>
                {subtab === 'Knowledge Bank' && folders?.length !== 0 &&
                    <div className='blog_list_wrap'>
                        {folders?.length !== 0 ?
                            folders.map((folder: any) => (
                                <span className={selfolder.name === folder.name ? 'blocks active' : 'blocks'}
                                    key={folder}
                                    onClick={() => {
                                        setFolder(folder);
                                        get_article(folder.id);
                                        setBreadcrumbPath(`home/services/latest updates/${subtab}/${folder?.name}`);
                                        setSelatricle({})
                                    }}>
                                    <span># </span>{folder.name}
                                </span>
                            ))
                            :
                            <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
                        }
                    </div>
                }
                <div className='content_gallery' style={{ width: Object.keys(selarticle).length > 0 ? '56%' : '82%' }}>

                    {Object.keys(selarticle).length > 0 ?
                        <div className='article_showcase'>
                            <div className='head_wrap'>
                                <span className='head_lines'>{selarticle.title}</span>
                                <span className='date_lines'>{selarticle.date}</span>
                            </div>
                            <div className='rel_content_wrap'>

                            </div>
                        </div> :
                        <>
                            {loading ?
                                <Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                </Spin> :
                                <>
                                    {subtab === 'Knowledge Bank' ? articles?.length !== 0 && articles?.map((item: any, index: any) => (
                                        <div className='content_row' key={item.subject}>
                                            <span className='content_subject'>
                                                <ArticleIcon className='icon' />
                                            </span>
                                            <span className='content_desc'
                                                onClick={() => {
                                                    get_particular_article(item.id)
                                                    setSelatricle(item)
                                                }}>{capitalizeFirstLetter(item.title)}
                                            </span>
                                        </div>)) :
                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
                                    }
                                </>
                            }
                        </>
                    }
                </div>
                {Object.keys(selarticle).length > 0 &&
                    <div className='relevant_folders'>
                        <span className='head_lines'>Related Articles</span>
                        <div className='rel_content_wrap'>
                            {loading ?
                                <Spin spinning={true} size='default' style={{ display: 'flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                                </Spin> :
                                <>
                                    {subtab === 'Knowledge Bank' ?
                                        articles?.length !== 0 && articles?.map((item: any, index: any) => (
                                            <div className='content_row' key={item.subject}>
                                                <span className='content_subject'>
                                                    <ArticleIcon className='icon' />
                                                </span>
                                                <span className='content_desc'
                                                    onClick={() => {
                                                        get_particular_article(item.id)
                                                        setSelatricle(item)
                                                    }}>
                                                    {capitalizeSubstring(item.title)}
                                                </span>
                                            </div>)) :
                                        <span style={{ color: 'rgba(0, 0, 0, 0.45)', margin: 'auto' }}>No data</span>
                                    }
                                </>
                            }
                        </div>
                    </div>}
            </div >
        )
    }
    // .....

    return (
        <>
            <Modal
                title={`${actionType === 'add' ? 'Add' : 'Edit'} Latest Update`}
                onCancel={() => { setLatestmodal(false); handleInitial() }}
                width={'27%'}
                className='action-popup status-chng-popup'
                centered
                style={{ color: "#031742" }}
                open={latestmodal}
                onOk={Addupdates}
                okText={`${actionType === 'add' ? 'Submit' : 'Update'}`}
                okButtonProps={{ disabled: isError() || formloading }}
            >
                <div style={{ height: '56vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                    <div className="col-sm-12 input-group" style={{ marginTop: '0rem' }}>
                        <label className="label">Update subject</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                autoComplete='off'
                                type="text"
                                name='update_subject'
                                value={update_subject.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter update subject"></input>
                            {update_subject.error && <p className="gst-err-msg" >{update_subject.error}</p>}
                        </div>
                    </div>
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">Update description</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                autoComplete='off'
                                type="text"
                                name='update_desc'
                                value={update_desc.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter update description"></input>
                            {update_desc.error && <p className="gst-err-msg" >{update_desc.error}</p>}
                        </div>
                    </div>
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">Update link</label>
                        <div className="input-box" >
                            <img className="icons" src={usernameIcon} alt='usernameIcon'></img>
                            <input
                                autoComplete='off'
                                type="text"
                                name='update_link'
                                value={update_link.value}
                                onBlur={handleChange}
                                onChange={handleChange}
                                placeholder="Enter update link"></input>
                            {update_link.error && <p className="gst-err-msg" >{update_link.error}</p>}
                        </div>
                    </div>
                    <div className="col-sm-12 input-group" style={{ marginTop: '1rem' }}>
                        <label className="label">Tags</label>
                        <TextField
                            fullWidth
                            focused
                            required
                            select
                            SelectProps={{ native: true, }}
                            variant='outlined'
                            name="tags"
                            // value={tags.value}
                            onBlur={handleChange}
                            onChange={handleChange}
                            autoComplete='off'
                            style={{ margin: '2% 0' }}
                        >
                            {Typooftags.map((type: any, index: any) =>
                                <option key={index} value={type}>{type.value}</option>
                            )}
                        </TextField>
                    </div>
                </div>
            </Modal>
            <Modal
                onCancel={() => setDeleteact(false)}
                centered
                closeIcon={false}
                className='delete-gst'
                style={{ textAlign: "center", color: "#031742", top: '-14%' }}
                open={deleteact}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <img src={deleteConfirmIcon} alt='action' />
                    <h2 className="del-header">Are you sure want to delete this update</h2>
                    <Button htmlType="submit" style={{ background: "none", color: "#ed7d31", border: "0.5px solid #ed7d31", width: "30%", height: "5vh", fontFamily: 'Inter' }} onClick={() => {
                        setDeleteact(false)
                    }}>CANCEL</Button>
                    <Button htmlType="submit" onClick={handleDelete} loading={formloading} style={{ background: "#424B70", color: "white", border: "none", width: "30%", height: "5vh", marginLeft: "10%", fontFamily: 'Inter' }}  >DELETE</Button>
                </Form>
            </Modal>
            <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            <div style={{ marginTop: '1%' }}>
                <Breadcrumbs />
            </div>
            <div className='latest_wrap'>
                {header()}
                {content()}
            </div>
            {/* <ZTable2 label={'Latest Updates'} button={buttondetails} columns={columns} expand={false} hideSelect={true} tabledata={updatesList} loading={loading} totalpages={totalpages} /> */}
        </>
    )
}