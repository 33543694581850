// react
import React, { createContext, useState, useContext, useEffect } from 'react';
// ..........

type StateContextType = {
    action: any;
    setAction: (value: any) => void;
    tab: any;
    setTab: (value: any) => void;
    menuclick: any;
    setMenuclick: (value: any) => void;
    globalsearch: any;
    setGlobalsearch: (value: any) => void;
    selectgst: any;
    setSelectgst: (value: any) => void;
    subselectgst: any;
    setSubselectgst: (value: any) => void;
    downloading: any;
    setDownloading: (value: any) => void;
    filterQuery: any;
    setFilterQuery: (value: any) => void;
    sorterQuery: any;
    setSorterQuery: (value: any) => void;
    searchquery: any;
    setSearchquery: (value: any) => void;
    noticeQuery: any;
    setNoticeQuery: (value: any) => void;
    chartquery: any;
    setChartquery: (value: any) => void;
    syncedCount: any;
    setSyncedCount: (value: any) => void;
    gstSyncModal: any;
    setGstSyncModal: (value: any) => void;
    totalpages: any;
    setTotalpages: (value: any) => void;
    page: any;
    setPage: (value: any) => void;
    tabactive: any;
    setTabactive: (value: any) => void;
    manageactive: any;
    setManageactive: (value: any) => void;
    tabview: any;
    setTabview: (value: any) => void;
    videopopup: any;
    setVideopopup: (value: any) => void;
    initialValues: any;
    setIntialvalues: (value: any) => void;
    reportmodal: any;
    setreportModal: (value: any) => void;
    syncDetails: any;
    setSyncDetails: (value: any) => void;
    sumdrawer: any;
    setSumdrawer: (value: any) => void;
    drawergst: any;
    setdrawergst: (value: any) => void;
    drawerquery: any;
    setdrawerquery: (value: any) => void;
    summarydetails: any;
    setSummarydetails: (value: any) => void;
    rowsPerPage: any;
    setRowsPerPage: (value: any) => void;
    statuschange: any;
    setStatuschange: (value: any) => void;
    selectedGstRows: any;
    setSelectedGstRows: (value: any) => void;
    addnotice: any;
    setAddnotice: (value: any) => void;
    syncProcess: any;
    setSyncProcess: (value: any) => void;
    drawerloading: any;
    setDrawerloading: (value: any) => void;
    userdata: any;
    setUserdata: (value: any) => void;
    sumpopup: any;
    setSumpopup: (value: any) => void;
    masterData: any;
    setMasterData: (value: any) => void;
    viewnoticeacitve: any;
    setViewnoticeactive: (value: any) => void;
    noticedata: any;
    setNoticedata: (value: any) => void;
    progres: any;
    setProgres: (value: any) => void;
    changeview: any;
    setChangeview: (value: any) => void;
    expanded: any;
    setExpanded: (values: any) => void;
    assignpopup: any;
    setAssignpopup: (value: any) => void;
    overviewDetails: any;
    setOverviewDetails: (values: any) => void;
    tableKey: any;
    setTableKey: (value: any) => void;
    ismanagegst: any;
    setIsManagegst: (value: any) => void;
    breadcrumbPath: any;
    setBreadcrumbPath: (value: any) => void;
};

const StateContext = createContext<StateContextType | null>(null);

export const useGlobalState = () => {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error('useGlobalState must be used within a StateProvider');
    }
    return context;
};



export const StateProvider: React.FC<any> = ({ children }) => {

    // loading state
    const [drawerloading, setDrawerloading] = useState(false);
    const [downloading, setDownloading] = useState(false);
    const [progres, setProgres] = useState<any>({
        data: {
            loading: false,
            label: ''
        }
    });

    // alerts and modal states
    const [videopopup, setVideopopup] = useState(false);
    const [gstSyncModal, setGstSyncModal] = useState(false);
    const [reportmodal, setreportModal] = useState(false);
    const [sumdrawer, setSumdrawer] = useState(false);
    const [statuschange, setStatuschange] = useState(false);
    const [addnotice, setAddnotice] = useState(false);
    const [sumpopup, setSumpopup] = useState(false);
    const [syncDetails, setSyncDetails] = useState<any>(null);
    const [expanded, setExpanded] = useState<boolean>(false);
    const [assignpopup, setAssignpopup] = useState(false);
    const [ismanagegst, setIsManagegst] = useState(() => {
        const savedState = localStorage.getItem('ismanagegst');
        return savedState ? JSON.parse(savedState) :
            false
    });
    // ..........

    // limit & filter states
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalpages, setTotalpages] = useState(0)
    const [page, setPage] = useState(1)
    const [filterQuery, setFilterQuery] = useState<any>(() => {
        const savedState = localStorage.getItem('filterQuery');
        return savedState ? JSON.parse(savedState) :
            {}
    })
    const [sorterQuery, setSorterQuery] = useState<any>({})
    const [searchquery, setSearchquery] = useState<any>('')
    const [chartquery, setChartquery] = useState<any>(false)
    const [noticeQuery, setNoticeQuery] = useState<any>(() => {
        const savedState = localStorage.getItem('noticeQuery');
        return savedState ? JSON.parse(savedState) :
            {}
    })
    const [breadcrumbPath, setBreadcrumbPath] = useState(() => {
        const savedState: string | null = localStorage.getItem('breadcrumbs');

        if (savedState) {
            // Parse the saved JSON string first
            const parsedState = JSON.parse(savedState);

            // Assuming parsedState is a string that needs to be split by '/'
            const pathSegments = parsedState.split('-').filter(Boolean); // Split by '/' and remove empty segments
            pathSegments.pop();
            // You can return the full path or a specific segment (e.g., last part of the path)
            return pathSegments.join('/');
        } else {
            // Return an empty object if no saved state is found
            return 'HOME';
        }

    })
    // ..........

    //  datas states
    const [action, setAction] = useState(false)
    const [tab, setTab] = useState('GSTR 1')
    const [menuclick, setMenuclick] = useState('All notices')
    const [globalsearch, setGlobalsearch] = useState('');
    const [selectgst, setSelectgst] = useState<any>(() => {
        const savedState = localStorage.getItem('selectgst');
        return savedState ? JSON.parse(savedState) :
            null
    })
    const [subselectgst, setSubselectgst] = useState<any>(() => {
        const savedState = localStorage.getItem('subselectgst');
        return savedState ? JSON.parse(savedState) :
            null
    })

    const [syncedCount, setSyncedCount] = useState(0)
    const [tabactive, setTabactive] = useState(() => {
        const savedata = localStorage.getItem('tabactive');
        return savedata ? JSON.parse(savedata) :
            'GSTIN Summary'
    });
    const [manageactive, setManageactive] = useState(() => {
        const savedata = localStorage.getItem('manageactive');
        return savedata ? JSON.parse(savedata) :
            'Active'
    })
    const [tabview, setTabview] = useState(false);
    const [viewnoticeacitve, setViewnoticeactive] = useState('Additional')
    const [initialValues, setIntialvalues] = useState({
        user_name: {
            value: '',
            error: '',
            required: true,
            validate: 'text',
        }
    })
    const [summarydetails, setSummarydetails] = useState(() => {
        const savedata = localStorage.getItem('sumarydetails');
        return savedata ? JSON.parse(savedata) :
            {}
    });
    const [selectedGstRows, setSelectedGstRows] = useState([]);
    const [syncProcess, setSyncProcess] = useState(false);
    const [userdata, setUserdata] = useState();
    const [masterData, setMasterData] = useState(() => {
        const savedState = localStorage.getItem('masterData');
        return savedState ? JSON.parse(savedState) :
            {
                gst: [],
                states: [],
                trades: [],
                caseIdFilter: [],
                arnFilter: [],
                sessionFilter: [],
                caseType: [],
                userNames: []
            }
    });
    const [noticedata, setNoticedata] = useState(null)
    const [changeview, setChangeview] = useState(false);
    const [drawergst, setdrawergst] = useState('')
    const [drawerquery, setdrawerquery] = useState('');
    const [overviewDetails, setOverviewDetails] = useState<any>(
        () => {
            const savedata = localStorage.getItem('overviewdata');
            return savedata ? JSON.parse(savedata) :
                { gst_no: '', trade_name: '' }
        }
    );
    const [tableKey, setTableKey] = useState(0);
    // ..........

    useEffect(() => {
        localStorage.setItem('noticeQuery', JSON.stringify(noticeQuery));
    }, [noticeQuery]);

    useEffect(() => {
        localStorage.setItem('masterData', JSON.stringify(masterData));
    }, [filterQuery]);

    useEffect(() => {
        localStorage.setItem('sumarydetails', JSON.stringify(summarydetails));
    }, [summarydetails, filterQuery]);

    useEffect(() => {
        localStorage.setItem('overviewdata', JSON.stringify(overviewDetails));
    }, [overviewDetails]);

    useEffect(() => {
        localStorage.setItem('ismanagegst', JSON.stringify(ismanagegst));
    }, [ismanagegst])

    useEffect(() => {
        localStorage.setItem('breadcrumbs', JSON.stringify(breadcrumbPath));
    }, [breadcrumbPath])

    useEffect(() => {
        localStorage.setItem('tabactive', JSON.stringify(tabactive));
    }, [tabactive])

    useEffect(() => {
        const savedData = localStorage.getItem('noticedata');
        if (savedData) {
            try {
                const parsedData = JSON.parse(savedData);
                setNoticedata(parsedData);
            } catch (error) {
                console.error('Error parsing JSON:', error);
                // Handle error, e.g., clear localStorage or set default value
            }
        }
    }, [])

    // Props
    const values = {
        action,
        setAction,
        tab,
        setTab,
        menuclick,
        setMenuclick,
        globalsearch,
        setGlobalsearch,
        selectgst,
        setSelectgst,
        subselectgst,
        setSubselectgst,
        downloading,
        setDownloading,
        filterQuery,
        setFilterQuery,
        sorterQuery,
        setSorterQuery,
        searchquery,
        setSearchquery,
        noticeQuery,
        setNoticeQuery,
        chartquery,
        setChartquery,
        syncedCount,
        setSyncedCount,
        gstSyncModal,
        setGstSyncModal,
        totalpages,
        setTotalpages,
        page,
        setPage,
        tabactive,
        setTabactive,
        manageactive,
        setManageactive,
        tabview,
        setTabview,
        videopopup,
        setVideopopup,
        initialValues,
        setIntialvalues,
        reportmodal,
        setreportModal,
        syncDetails,
        setSyncDetails,
        sumdrawer,
        setSumdrawer,
        drawergst,
        setdrawergst,
        drawerquery,
        setdrawerquery,
        summarydetails,
        setSummarydetails,
        rowsPerPage,
        setRowsPerPage,
        statuschange,
        setStatuschange,
        selectedGstRows,
        setSelectedGstRows,
        addnotice,
        setAddnotice,
        syncProcess,
        setSyncProcess,
        drawerloading,
        setDrawerloading,
        userdata,
        setUserdata,
        sumpopup,
        setSumpopup,
        masterData,
        setMasterData,
        viewnoticeacitve,
        setViewnoticeactive,
        noticedata,
        setNoticedata,
        progres,
        setProgres,
        changeview,
        setChangeview,
        expanded,
        setExpanded,
        assignpopup,
        setAssignpopup,
        overviewDetails,
        setOverviewDetails,
        tableKey,
        setTableKey,
        ismanagegst,
        setIsManagegst,
        breadcrumbPath,
        setBreadcrumbPath
    }
    // ..........

    return (
        <StateContext.Provider value={values} >
            {children}
        </StateContext.Provider >
    );
};
