// react 
import React, { useMemo } from 'react'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { Link, useNavigate } from 'react-router-dom'
// ..........

// scss file
import './Casenotice.scss'
// ..........

// package components
import type { CalendarProps } from 'antd'
import { TextareaAutosize } from '@mui/base/TextareaAutosize';
import { DatePicker as AntDatePicker, Button, Calendar, Checkbox, Form, Input, Modal, Radio, RadioChangeEvent, Select, Spin, Tag, Tooltip } from 'antd'
import Table from 'antd/es/table'
import dayjs, { Dayjs } from 'dayjs'
import axios from 'axios'
import { Badge, IconButton, TextField } from '@mui/material'
import moment from 'moment'
// ..........

// child components
import { useGlobalState } from '../../Statecontext'
import Chart from '../../components/chart/index'
import { NotifyAlert } from '../../components/notify_alert'
import { ZTable2 } from '../../components/table2'
import { serviceConfig } from '../../core/services'
import { AppContext } from '../../core/utils/Context'
import { Utils } from '../../core/utils/utils'
// ..........

// icons
import attachIcon from '../../assets/images/attach_icon.svg'
import arrowRightIcon from '../../assets/images/chevron-right.svg'
import expandIcon from '../../assets/images/expandIcon.svg'
import expandIcon1 from '../../assets/images/expandIcon2.svg'
import filterIcon from '../../assets/images/filter.svg'
import active_filtericon from '../../assets/images/filter_active_icon.svg'
import gstIcon from '../../assets/images/gstin managed.svg'
import incorrectPasswordIcon from '../../assets/images/incorrect password.svg'
import noticeIcon from '../../assets/images/total notice count.svg'
import closeIcon from '../../assets/register/close.png'
import editIcon from '../../assets/images/editIcon.svg'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CheckIcon from '@mui/icons-material/Check';
import { CheckOutlined, EditOutlined } from '@ant-design/icons';
// ..........

interface CaseData {
    id: string;
    case_type_name: string;
    case_ref_id: string[];
    case_arn_no: string;
    case_description: string[];
    // Add other fields as necessary
}

interface GST {
    gst_no: string;
    trade_name: string;
    state: string;
    // Add other fields as necessary
}

interface ApiResponse {
    id: string;
    gst: GST;
    notice_status: string;
    notice_item_id: string | null;
    issue_date: string;
    due_date: string;
    case_data: CaseData;
    // Add other fields as necessary
    state: string;
    tax_amount: number;
    tax_period: string;
    attachments: any;
    // ...other fields
}

interface FormValues {
    portal_status: string;
    state: string;
    trade_name: string;
    gst_no: string;
    gstin_input: string;
    tax_period: string;
    refid: string;
    caseid: string;
    casetype: string;
    section: string;
    taxamount: number;
    // Add other form values as necessary
}

interface casenoticeprops {
    gstin: any;
    selectedgst: any;
}

export const Casenotice: React.FC<casenoticeprops> = (props) => {

    const { TextArea } = Input;

    // form values states
    const userData: any = localStorage.getItem("userData");
    const [duedate, setDuedate] = useState<any>(null)
    const [noticedate, setNoticedate] = useState<any>(null)
    const [tax_startdate, setStartdate] = useState<any>('')
    const [tax_enddate, setEnddate] = useState<any>('')
    const [States, setStates] = useState([]);
    const [Gst_in, setGstin] = useState([])
    const [gstid, setGstid] = useState('')
    const [noticeid, setNoticeid] = useState('')
    const [Trade_name, setTradename] = useState('')
    const [selectedstate, setSelectedState] = useState('')
    const [managegstdetails, setManagegstdetails] = useState([])
    const [actionType, setActionType] = useState("add")
    const [editId, setEditId] = useState('')
    const [remark, setRemark] = useState<Record<string, string>>({});
    const [editingRow, setEditingRow] = useState<string | null>(null);
    // ..........

    // alerts and modal states
    const [notifyOpen, setNotifyOpen] = useState(false)
    const [notifyType, setNotifyType] = useState<any>()
    const [notifyMessage, setNotifyMessage] = useState('')
    var [tableHeader, setTableHeader] = useState<any>()
    const [noticeStatus, setNoticeStatus] = useState('Action to be taken');
    const [selectedOrgFiles, setSelectedOrgFiles] = useState<any>()
    const [selectedFiles, setSelectedFiles] = useState<any>([])
    const [sourcefiles, setSourceFiles] = useState([])
    const inputFileElement = useRef<any>();
    const { formValues, handleChange, handleInitial } = useContext(AppContext);
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const { casestatus, state, trade_name, gstin, gstin_input, tax_period, refid, caseid, casetype, section, taxamount } = formValues;
    // ..........

    // loading state
    const [loading, setLoading] = useState(false)
    // ..........

    // limit & filter states
    const gstTableElement = useRef<any>();
    const [fromDueDate, setFromDueDate] = useState<any>('')
    const [toDueDate, setToDueDate] = useState<any>('')
    const [fromTaxAmount, setFromTaxAmount] = useState<any>('')
    const [amountOperator, setAmountOperator] = useState<any>('eq')
    const [filter, setFilter] = useState(false)
    const [secondaryFilter, setSecondaryFilter] = useState<any>([])
    const [secFilterCase, setSecFilterCase] = useState('')
    const [checkedArnItems, setCheckedArnItems] = useState<string[]>([])
    const [checkedCaseIdItems, setCheckedCaseIdItems] = useState<string[]>([])
    const [checkedSessionItems, setCheckedSessionItems] = useState<string[]>([])
    const [checkedItems, setCheckedItems] = useState<string[]>([]);
    const { setreportModal, setSyncDetails, setProgres, noticeQuery, expanded, setExpanded, masterData, setMasterData, overviewDetails, filterQuery, setFilterQuery, setNoticeQuery, sorterQuery, searchquery, setSearchquery, setSorterQuery, chartquery, setChartquery, gstSyncModal, setGstSyncModal, setTabview, totalpages, setTotalpages, page, setPage, setRowsPerPage, tabactive, setTabactive, sumpopup, setSumdrawer, drawerloading, setDrawerloading, rowsPerPage, statuschange, setStatuschange, selectedGstRows, setSelectedGstRows, addnotice, setAddnotice, userdata, setNoticedata } = useGlobalState();
    const [secStateFilter, setSecStateFilter] = useState<any>({ state: [], arn_nos: [], case_ids: [], section: [] })
    const [x, setX] = useState(0)
    const [y, setY] = useState(0)
    // ..........

    //  datas states
    const [gstCounts, setGstCounts] = useState<any>()
    const [noticeData, setNoticeData] = useState<any>([]);
    const [selected, setSelected] = useState('Select Action')
    const TypeOfSolution = [
        { id: 1, value: 'No action required' },
        { id: 2, value: 'Action to be taken' },
        { id: 3, value: 'Action taken' },
    ]
    const Tax_period = [
        {
            id: 1,
            value: 'Jul 2017 - Mar 2018',

        },
        {
            id: 2,
            value: 'Apr 2018 - Mar 2019',

        },
        {
            id: 3,
            value: 'Apr 2019 - Mar 2020',

        },
        {
            id: 4,
            value: 'Apr 2020 - Mar 2021',

        },
        {
            id: 5,
            value: 'Apr 2021 - Mar 2022',

        },
        {
            id: 6,
            value: 'Apr 2022 - Mar 2023',

        }
    ]
    // ..........

    // reference states
    const [isMobile, setIsMobile] = useState<boolean>(false);
    const abortControllerRef = useRef<AbortController | null>(null);
    const navigate = useNavigate()
    const manageGstDataLoading = useRef(false)
    const ismanageRef = useRef(false);
    const { ticket, MultiNoticeUpdate, handlesync } = Utils();
    // ..........

    // data get function

    const loadGNotices = useCallback((search_data?: any) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        const controller = new AbortController();
        abortControllerRef.current = controller;
        const signal = controller.signal;
        setLoading(true)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        }
        if (searchquery) {
            query['search'] = searchquery;
        }
        if (filterQuery.gst_no) {
            query['gst_no'] = filterQuery.gst_no.join(',')
        }
        if (overviewDetails.gst_no) {
            query['gst_no'] = overviewDetails.gst_no
        }
        if (filterQuery.state && filterQuery.state.length > 0) {
            query['state'] = filterQuery.state.join(',')
        }
        if (filterQuery.trade_name) {
            query['trade_name'] = filterQuery.trade_name.join(',')
        }
        if (filterQuery.tradename) {
            query['trade_name'] = filterQuery.tradename.join(',')
        }
        if (filterQuery.user_name) {
            query['user_name'] = filterQuery.user_name.join(',')
        }
        if (filterQuery.tax_period && filterQuery.tax_period.length > 0) {
            query['tax_period'] = filterQuery.tax_period.join(',')
        }
        if (filterQuery.arn_nos && filterQuery.arn_nos.length > 0) {
            query['arn_nos'] = filterQuery.arn_nos.join(',')
        }
        if (filterQuery.case_ids && filterQuery.case_ids.length > 0) {
            query['case_ids'] = filterQuery.case_ids.join(',')
        }
        if (filterQuery.section && filterQuery.section.length > 0) {
            query['section'] = filterQuery.section.join(',')
        }
        if (filterQuery.notice_status && filterQuery.notice_status.length > 0) {
            query['notice_status'] = filterQuery.notice_status.join(',')
        }
        if (filterQuery.due_day && filterQuery.due_day.length > 0) {
            query['days'] = filterQuery.due_day.join(',')
        }
        if (filterQuery.due_from_date && filterQuery.due_to_date) {
            query['notice_start_due_date'] = filterQuery.due_from_date
            query['notice_end_due_date'] = filterQuery.due_to_date
        }
        if (filterQuery.notice_from_date && filterQuery.notice_to_date) {
            query['notice_start_issue_date'] = filterQuery.notice_from_date
            query['notice_end_issue_date'] = filterQuery.notice_to_date
        }
        if (filterQuery.amount_from && filterQuery.amount_oper) {
            query['tax_amount'] = filterQuery.amount_from
            if (filterQuery.amount_oper) {
                query['tax_amount_op'] = filterQuery.amount_oper
            }
        }
        if (sorterQuery && sorterQuery.columnKey) {
            query['sort'] = `${sorterQuery.columnKey},${sorterQuery.order === "ascend" ? "asc" : "desc"}`
        }

        if (tabactive === 'Additional' || tabactive === 'Manual') {
            query['master_type'] = tabactive
        } else if (tabactive === 'Latest') {
            query['master_type'] = 'Additional'
        }

        const url = tabactive === 'GSTIN Summary' ? "get_group_notices" :
            tabactive === 'Notices & Orders' ? "get_notices_orders" :
                tabactive === 'Latest' ? "get_latest_notices" : "get_notices";

        serviceConfig.get(url, true, query, null, signal).then((data: any) => {
            if (!signal.aborted) {
                setNoticeData(data.results || data.data.results || data.data)
                setTotalpages(data.count)
                setLoading(false)
            }
        }, (err: any) => {
            if (!signal.aborted) {
                console.log(err)
                setLoading(false)
                if (err.message === "Request failed with status code 401") {
                    navigate('/login')
                    localStorage.clear()
                    window.location.reload();
                } if (err.message === "Network Error") {
                    setNotifyOpen(true)
                    setNotifyType('error')
                    setNotifyMessage('Something Went Wrong!')
                    setSorterQuery({})
                }
            }
        })
        return () => {
            controller.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, filterQuery, sorterQuery, searchquery, tabactive, chartquery])

    // handledrawerfilter
    const handledrawerfilter = (gst_no?: any, query?: any, is_over_due?: any) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }

        // Create a new AbortController for the new request
        const controller = new AbortController();
        abortControllerRef.current = controller;
        const signal = controller.signal;
        setLoading(true)

        let gst = props.gstin;

        let updatedQuery: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        };
        if (noticeQuery && noticeQuery.gst) {
            updatedQuery['gst'] = gst
            if (noticeQuery && noticeQuery.notice_status) {
                updatedQuery['notice_status'] = [noticeQuery.notice_status]
            }
        }

        if (noticeQuery && noticeQuery.is_over_due) {
            updatedQuery['gst'] = gst
            updatedQuery['is_over_due'] = true
        }
        if (noticeQuery && noticeQuery.gst_case_id) {
            updatedQuery['master_type'] = noticeQuery.master_type
            updatedQuery['gst_case_id'] = noticeQuery.gst_case_id
        }
        if (noticeQuery && noticeQuery.is_due) {
            updatedQuery['is_due'] = true
            updatedQuery['notice_start_due_date'] = noticeQuery.notice_start_due_date
            updatedQuery['notice_end_due_date'] = noticeQuery.notice_end_due_date
        }
        updatedQuery['sort'] = `issue_date,desc`
        setNoticeData([])
        setTotalpages(0)

        serviceConfig.get("get_notices", true, updatedQuery, null, signal).then((data: any) => {
            if (!signal.aborted) {
                setNoticeData(data.results || data)
                setTotalpages(data.count)
                setLoading(false)
            }
        }, (err: any) => {
            if (!signal.aborted) {
                console.log(err)
                setLoading(false)
                setNotifyOpen(true)
                setNotifyType('Error')
                setNotifyMessage('Something Went Wrong!')
                navigate(-1)
            }
        })
        return () => {
            controller.abort();
        };
    }
    // ......

    useEffect(() => {
        handledrawerfilter()
        return () => {
            if (abortControllerRef.current) {
                abortControllerRef.current.abort();
            }
        };
    }, [props.gstin, noticeQuery])


    useEffect(() => {
        const mediaQuery = window.matchMedia('(max-width: 768px)'); // Adjust the max-width according to your mobile breakpoint
        setIsMobile(mediaQuery.matches);

        const handleResize = () => setIsMobile(mediaQuery.matches);

        mediaQuery.addListener(handleResize);

        return () => {
            mediaQuery.removeListener(handleResize);
        };
    }, []);

    // ...........

    // viewticket
    const handleviewticket = (value: any) => {
        // navigate(`/home/${value}/view-notices`)
        let query: any = {
            by_page: true,
            page: page,
            page_size: rowsPerPage,
        }
        let notice_id: any = value;

        // Ensure notice_id is an array
        if (!Array.isArray(notice_id)) {
            // If notice_id is not an array, convert it to an array containing only the original value
            notice_id = [notice_id];
        }

        query['gst_case_id'] = notice_id.join(',')

        query['master_type'] = 'Additional'

        setNoticeQuery(query)
        navigate(`/home/${value}/case-summary-details`)
    }
    // ...........

    // sync initiate function
    const syncProgress = useRef(false)
    let [syncId, setSyncId] = useState<any>(null)
    const synccallfunction = (selectedGST?: any) => {
        console.log([props.selectedgst])
        handlesync([props.selectedgst])
            .then((data: any) => {
                syncId = data.data.syncId
                setSyncId(data.data.syncId)
                setGstSyncModal(true)
                setTimeout(() => setGstSyncModal(false), 40000)
                syncProgress.current = false;
                getStatusofSyncAllProgress()
                loadGNotices()
            })
            .catch((err: any) => {
                setNotifyType("error")
                console.log(err)
                setNotifyMessage(err.response.message)
                setNotifyOpen(true)
                syncProgress.current = false;
            });
    }

    const getStatusofSyncAllProgress = () => {
        setProgres({ data: { loading: true, label: 'Sync' } })
        serviceConfig.get("gst_sync_details", true, null, { id: syncId }).then((data: any) => {
            setSyncDetails(data)
            // setIsSyncAllProgress((data.is_sync_all && !data.sync_processed) ? true : false)
            syncId = data.id
            setSyncId(data.id)
            let timerInt = !data.sync_processed ? setTimeout(() => {
                if (ismanageRef.current) {
                    loadGNotices();
                }
                // { ismanage && loadGst() }
                getStatusofSyncAllProgress();
            }, (10 * 1000)) : null;
            if (data.sync_processed) {
                timerInt = null;
                setreportModal(true)
                syncProgress.current = false;
                if (ismanageRef.current) {
                    loadGNotices();
                }
                // { ismanage && loadGst() }
                manageGstDataLoading.current = false;
                setProgres({ data: { loading: false, label: '' } })
            }
        }, (err: any) => {
            console.log(err)
            setProgres({ data: { loading: false, label: '' } })
        })
    }
    // ........

    // Handle change function for textarea
    const handleremarkChange = (id: string, e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setRemark({ ...remark, [id]: e.target.value });
    };
    const remarkupdate = (data: any) => {
        console.log('Submitted value:', remark);
        const updatedRemark = remark[data.id] || data.remarks || '';

        let query = {
            'id': data.id,
            'remarks': updatedRemark
        }
        const url = tabactive === 'GSTIN Summary' ? "get_group_notices" :
            tabactive === 'Notices & Orders' ? "get_notices_orders" :
                tabactive === 'Latest' ? "get_latest_notices" : "get_notices";

        serviceConfig.put(url, true, query, null).then((data: any) => {
            setPage(1)
            loadGNotices()
            setNotifyOpen(true)
            setNotifyType('success')
            setNotifyMessage('Remark updated successfully!')
            setRemark({});
            setEditingRow(null);
        }).catch((err: any) => {
            console.log(err)
            setLoading(false)
            if (err.message === "Request failed with status code 401") {
                navigate('/login')
                localStorage.clear()
                window.location.reload();
            } if (err.message === "Network Error") {
                setNotifyOpen(true)
                setNotifyType('error')
                setNotifyMessage('Something Went Wrong!')
                setSorterQuery({})
            }
        })
    }
    //   .....

    
    // getsolution function	
    const createTickets = (selectedNotice?: any) => {
        if (selectedNotice) {
            ticket(selectedNotice).then((data: any) => {
                setNotifyType("success")
                setNotifyMessage("Our experts team contact you shortly.")
                setNotifyOpen(true)
                loadGNotices()
            }).catch((err: any) => {
                setNotifyType("error")
                setNotifyMessage(err.response.message)
                setNotifyOpen(true)
            })
        } else {
            setNotifyType("warning")
            setNotifyMessage("Please select atleast one notice to get solution!")
            setNotifyOpen(true)
        }
    }
    // ...........

    // button details props
    const buttondetails: any = [
        {
            type: 'get',
            name: 'Get Solution from Zen Tax Clinic',
            theme: 'theme2',
            conditions: ['if_list'],
            function: createTickets
        },
        // userData.user_counts > 0 &&
        {
            type: 'sync',
            name: 'Sync Now',
            theme: 'theme3',
            conditions: [],
            function: synccallfunction
        },
        {
            type: 'download',
            name: 'Download',
            theme: 'theme4',
            conditions: ['if_list'],
            downloadquery: 'get_case_notices'
        }
    ]
    // ............

    // table columns and filter requirement props
    const formatter = new Intl.NumberFormat('en-IN');

    const casenoticecolumn = [
        {
            title: <>
                <span style={{ position: 'relative', top: '-17%' }}>Case Id</span></>,
            dataIndex: 'case_data', key: 'case_data',
            render(value: any, record: any, index: any) {
                return (
                    record.is_latest ?
                        <Tooltip placement="topLeft" title={'Latest notice'} arrow={true} color={'#424B70'}>
                            <Badge
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                variant="dot"
                                id='latst_dot'
                            >
                                <span onClick={() => {
                                    handleviewticket(noticeQuery.gst_case_id)
                                }} style={{ whiteSpace: 'pre-wrap', color: '#E96919', cursor: 'pointer', fontWeight: 'bold' }} > {value ? value.case_arn_no : '--'}</span >
                            </Badge >
                        </Tooltip> :
                        <span onClick={() => {
                            handleviewticket(noticeQuery.gst_case_id)
                        }} style={{ whiteSpace: 'pre-wrap', color: '#E96919', cursor: 'pointer', fontWeight: 'bold' }} > {value ? value.case_arn_no : '--'}</span >

                )
            },
            width: 70,
        },
        {
            title: <>
                <span style={{ position: 'relative', top: '-17%' }}>Notice Type</span></>,
            dataIndex: 'notice_type', key: 'notice_type',
            render(value: any, record: any, index: any) {
                return value || '--'
            },
            width: 200,
        },
        {
            title: 'Notice date', dataIndex: 'issue_date', key: 'issue_date',
            render(value: any, record: any, index: any) {
                return value && value.indexOf('/') !== -1 ? value.split('/').join('-') : value ? value : '--'
            },
            width: 100,
        },
        {
            title: 'Description', dataIndex: 'case_data', key: 'reason',
            render(value: any, record: any, index: any) {
                // function formatDesc(descs: any) {
                // 	let desc_data: any = []
                // 	descs.forEach((des: any) => {
                // 		desc_data.push(des.split(' ').pop())
                // 	});
                // 	return descs.pop()
                // }
                return (
                    <span style={{ whiteSpace: 'break-spaces' }}>
                        {value && value.case_description && value.case_description.length > 0
                            ? value.case_description[value.case_description.length - 1]
                            : '--'}{' '}
                        {value && value.case_description && value.case_description.length > 1 && (
                            <Tooltip style={{ cursor: 'pointer' }} title={value.case_description.join(', ')}>
                                ({value.case_description.length})
                            </Tooltip>
                        )}
                    </span>
                    // <span style={{ whiteSpace: 'break-spaces' }}>{value.case_description.length > 0 ? value.case_description[value.case_description.length - 1] : '--'} {value.case_description.length > 1 && <Tooltip style={{ cursor: 'pointer' }} title={value.case_description.join(', ')}>({value.case_description.length})</Tooltip>}</span>
                )
            },
            width: 190,
        },
        {
            title: 'Tax amount', dataIndex: 'tax_amount', key: 'tax_amount',
            render(value: any, record: any, index: any) {
                return (<span style={{ whiteSpace: 'pre-wrap' }}>{value && value ? '₹ ' + formatter.format(Number(value.toFixed(2))) : "--"}</span>)
            },
            width: 150,
        },
        {
            title: 'Status', dataIndex: 'notice_status', key: 'notice_status',
            render(value: any, record: any, index: any) {
                if (value === "No action required") {
                    return <Tag color='error' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
                }
                if (value === "Action to be taken") {
                    return <Tag color='warning' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
                }
                if (value === "Action taken") {
                    return <Tag color='success' style={{ borderRadius: "25px", padding: '3px 10px', cursor: 'pointer' }} >{value}</Tag>
                }
            },
            width: 150,
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks', key: 'remarks',
            maxWidth: 180,
            render(value: any, record: any, index: any) {
                const isEditing = editingRow === record.id;

                const remarks: string = remark[record.id] || value || '';

                return isEditing ? (
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <TextArea
                            placeholder="Enter remarks"
                            value={remarks}  // Ensure the remark is prefilled
                            onChange={(e) => handleremarkChange(record.id, e)} // Handle remark change
                            style={{ width: '12vw' }}
                            autoSize={{ minRows: 1, maxRows: 5 }}
                            allowClear
                        />
                        <Button
                            style={{ height: '25px', width: '25px' }}
                            onClick={() => remarks ? remarkupdate(record) : setEditingRow(null)}  // Submit the update
                            icon={<CheckOutlined />}
                            type="primary"
                        />
                    </div>
                ) : (
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <span style={{ whiteSpace: 'pre-wrap' }}>{value || 'No remarks'}</span>
                        <Button
                            style={{ color: '#424B70' }}
                            onClick={() => setEditingRow(record.id)}  // Switch to edit mode
                            icon={<EditOutlined color='#424B70' />}
                            type="link"
                        />
                    </div>
                );
            },
            width: 160
        },
        // {
        //     title: "Action",
        //     dataIndex: "sync_status",
        //     fixed: "right",
        //     render(value: any, record: any, index: any) {
        //         return (
        //             <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "8%", fontSize: "20px", color: "#00000045", cursor: "default" }} >
        //                 <img src={editIcon} alt='password icon' style={{ cursor: "pointer" }} onClick={() => {
        //                     console.log(record)
        //                 }
        //                 } />
        //                 <RemoveRedEyeIcon fontSize='medium' className='syncview-icon' onClick={() => { setNoticedata(record); localStorage.setItem('noticedata', JSON.stringify(record)); navigate(`/home/${record.id}/view-gst-details`) }} />
        //             </div>
        //         )
        //     }
        // },
        Table.SELECTION_COLUMN,
    ];
    // ............

    return (
        <>
            <div id="gst-parent-container" className="gst-container" >

                <Modal
                    title={""}
                    onCancel={() => setGstSyncModal(false)}
                    width={'35%'}
                    className='action-popup'
                    centered
                    style={{ color: "#031742" }}
                    open={gstSyncModal}
                    okButtonProps={{ style: { display: 'none' } }}
                    cancelButtonProps={{ style: { display: 'none' } }}
                >
                    <div>
                        <b>Sync scheduled successfully</b>
                        <p>You can minimize this tab or even close the website, and return later to check the process. You're all set!</p>
                    </div>
                </Modal>

                <div className='chart-table-cover'>
                    <div className='manual-table-wrap' >
                        <ZTable2
                            label={''}
                            button={buttondetails}
                            columns={casenoticecolumn}
                            expand={false}
                            ref={gstTableElement}
                            tabledata={noticeData}
                            loading={loading}
                            hideSelect={false}
                            chartclick={chartquery}
                            totalpages={totalpages} />
                    </div>

                </div>

                <NotifyAlert notifyOpen={notifyOpen} changeStatus={setNotifyOpen} notifyMessage={notifyMessage} notifyType={notifyType}></NotifyAlert>
            </div >
        </>
    )
}